import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Asset, ContractorBasicInfo, Employee, Service, Subcontractor, User } from '../../../../_shared/model';
import { Diagnosis } from '../../../../_shared/model/portfolio/diagnose';
import { GroupTag } from '../../../../_shared/model/settings/groupTag.model';
import { HelperService, StorageService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { ContractorPrintHeader } from './../../../../_shared/model/settings/contractorPrintHeader.model';
import { HeaderPrint } from './../../../../_shared/model/settings/header-print.model';
import { WizardListElement } from './../../../../_shared/model/wizardListElement.model';

@Injectable()
export class CommonService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: any;

    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    public getContractorHeaders(contractorid: number): Observable<HeaderPrint> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/print/rules`);
    }
    public changeContractorHeaders(contractorid: number, data: ContractorPrintHeader) {
        return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${contractorid}/print/rules`, data);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteGroupTag(contractorid: number, tagId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractors/${contractorid}/group-tags/${tagId}`);
    }

    public getGroupTags(contractorid: number): Observable<GroupTag[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/group-tags`);
    }

    //getContractorAppUsers()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getContractorAppUsers(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + cid + '/users');
    }

    // updateEmployee()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateEmployee(contractorid: number, employeeId: number, employee: Employee): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + contractorid + '/employees/' + employeeId, employee);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createEmployee(contractorid: number, employee: Employee): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/employees/', employee);
    }

    //getEmployee()
    public getEmployee(cid: number, employeeid: number): Observable<Employee> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/employees/' + employeeid);
    }

    //getEmployees()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllEmployees(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + cid + '/employees');
    }

    public getAllAssets(cid: number): Observable<Asset[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + cid + '/assets');
    }

    //getContractorSubcontractors()
    public getAllSubcontractors(cid: number, showAll: boolean = false): Observable<Subcontractor[]> {
        if (showAll) {
            return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors');
        }
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors').pipe(
            map(data => {
                return data.filter(el => el.active);
            }),
        );
    }
    //GetSubcontractor()
    public getSubcontractor(cid: number, sid: number): Observable<Subcontractor> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors/' + sid);
    }

    public getServicesForSubcontractor(cid: number, scid: number, bsid?: number): Observable<Service[]> {
        let param = '';
        if (bsid) {
            param = `?bs=${bsid}`;
        }
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + cid + '/subcontractors/' + scid + '/services' + param);
    }

    public getServicesForContractor(cid: number): Observable<Service[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${cid}/services`);
    }

    // localhost:9190/booking/eav2/erp/api/contractors/54/subcontractors/10/services/groups
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getGroupsForSubContractor(contractorId: number, subContractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/subcontractors/${subContractorId}/services/groups`);
    }

    public getSubcontractorsForGroup(contractorId: number, groupId: number) {
        // localhost:9190/booking/eav2/erp/api/contractors/54/services/groups/17/subcontractors
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups/${groupId}/subcontractors`);
    }

    public getServiceForContractor(contractorid: number, serviceid: number): Observable<Service> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/conservices/${serviceid}`);
    }

    //getSubcontractorsDeliveringService()
    public getSubcontractorsDeliveringService(contractorid: number, serviceid: number): Observable<Subcontractor[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/services/${serviceid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public searchCustomers(query: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getApiUrl(2) + '/contractors/' + this.storage.getSelectedContractor().id + '/customers/search?query=' + query,
        );
    }

    public searchDiagnose(query: string): Observable<Diagnosis[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/diagnoses/search?query=' + query);
    }

    public getDiagnosis(code: string): Observable<Diagnosis> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/diagnoses/${code}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getContractorEmployees(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/employees');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setAppPositionVisit(visitId: number): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/app/visit/' + visitId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteAppPosition(): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + '/app');
    }

    //changePwd()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public changePwd(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/user/pwd', data);
    }
    //getUserRoles()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getUserRoles(contractorid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/roles/' + contractorid);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getUserRequestLinks(userId: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/user/${userId}/contractorUserLinkRequests`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public confirmContractorUserLinkRequest(requestId: number): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractorUserLinkRequest/${requestId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getContractorLinkRequests(contractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorId}/contractorUserLinkRequests`);
    }

    public cancelContractorLinkRequest(requestId: number) {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractorLinkRequest/${requestId}`);
    }
    public cancelUserLinkRequest(requestId: number) {
        return this.httpClient.delete(this.helper.getApiUrl() + `/userLinkRequest/${requestId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deactivateUser(cid: number, userId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractors/${cid}/users/${userId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createNewContractorUser(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + `/contractors/${cid}/contractorNewUserRequest`, data);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveShowNewTaskReceivedNotification(cid: number, userid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + `/contractors/${cid}/users/${userid}/showNewTaskReceivedNotification`, data);
    }

    //addUserRole()
    // public addUserRole(cid: number, uid: number, data: string[]): Observable<any> {
    //     return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + cid + '/users/' + uid + '/roles', data);
    // }

    //setUserRoles()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setUserRoles(cid: number, uid: number, data: string[]): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl(2) + '/contractors/' + cid + '/users/' + uid + '/roles', data);
    }

    //getZipCodes()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getZipCodes(): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/zipcodes');
    }

    //getAllRoles()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllRoles(contractorid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/users/roles`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendToSlack(data: any): void {
        this.httpClient
            .postClient(environment.ERROR_URL, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            })
            .subscribe(
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                res => {},
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                err => {},
            );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendToSlack2(data: any): void {
        this.httpClient
            .postClient(environment.ERROR_URL, data, {
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            })
            .subscribe(
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                res => {},
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                err => {},
            );
    }
    public getContractorBasicInfo(cid: number): Observable<ContractorBasicInfo> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/basicinfo/' + cid);
    }

    public getContractorCouponCode(cid: number, numberOf?: number) {
        if (numberOf) {
            return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${cid}/coupons/next?numberOfCoupons=${numberOf}`);
        }
        return this.httpClient.get(this.helper.getAccountingUrl(2) + '/contractors/' + cid + '/coupons/counter');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateSubcontractor(contractorid: number, subcontractorid: number, subcontractor: Subcontractor): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + contractorid + '/subcontractors/' + subcontractorid, subcontractor);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createSubcontractor(contractorid: number, subcontractor: Subcontractor): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/subcontractors/', subcontractor);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public generateSlackObj(title: string, type: string, error: any, file?: string): any {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let slackData: any = {};
        try {
            let user = 'Unknown';
            let color = '898989';
            if (type === 'ERROR') {
                color = 'ed1c24';
            }
            if (type === 'WARNING') {
                color = 'ffde00';
            }

            if (type === 'INFO') {
                color = '0072bc';
            }

            if (this.storage.getUserData()) {
                user = this.storage.getUserData().name + ' (' + this.storage.getUserData().id + ')';
            }

            if (error._body && typeof error._body === 'string') {
                const errorBody = error;
                slackData = {
                    attachments: [
                        {
                            fallback: type + ' - ' + title,
                            color: '#' + color,
                            pretext: type + ' - ' + title,
                            // author_name: title,
                            // text: 'Optional text that appears within the attachment',
                            fields: [
                                {
                                    title: 'User',
                                    value: user,
                                    short: true,
                                },
                                {
                                    title: type,
                                    value: errorBody.error,
                                    short: true,
                                },
                                {
                                    title: 'Status',
                                    value: error.status,
                                    short: true,
                                },
                                {
                                    title: 'File',
                                    value: file ? file : 'Unknown',
                                    short: true,
                                },
                                {
                                    title: 'URL',
                                    value: error.url,
                                    short: false,
                                },
                                {
                                    title: 'Description',
                                    value: errorBody.error_description,
                                    short: false,
                                },

                                // {
                                //     title: 'Full stack',
                                //     value: '```' + JSON.stringify(error) + '```',
                                //     short: false
                                // }
                            ],
                        },
                    ],
                };
            } else if (error.message && error.stack) {
                slackData = {
                    attachments: [
                        {
                            fallback: type + ' - ' + title,
                            color: '#' + color,
                            pretext: type + ' - ' + title,
                            // author_name: title,
                            // text: 'Optional text that appears within the attachment',
                            fields: [
                                {
                                    title: 'User',
                                    value: user,
                                    short: true,
                                },
                                {
                                    title: type,
                                    value: 'ngError',
                                    short: true,
                                },
                                {
                                    title: 'File',
                                    value: file ? file : 'Unknown',
                                    short: true,
                                },
                                {
                                    title: 'Description',
                                    value: error.message,
                                    short: false,
                                },

                                // {
                                //     title: 'Full stack',
                                //     value: '```' + error.stack + '```',
                                //     short: false
                                // }
                            ],
                        },
                    ],
                };
            } else {
                slackData = {
                    attachments: [
                        {
                            fallback: type + ' - ' + title,
                            color: '#' + color,
                            pretext: type + ' - ' + title,
                            // author_name: title,
                            // text: 'Optional text that appears within the attachment',
                            fields: [
                                {
                                    title: 'User',
                                    value: user,
                                    short: true,
                                },
                                {
                                    title: type,
                                    value: 'ngError',
                                    short: true,
                                },
                                {
                                    title: 'File',
                                    value: file ? file : 'Unknown',
                                    short: true,
                                },
                                // {
                                //     title: 'Full stack',
                                //     value: '```' + JSON.stringify(error) + '```',
                                //     short: false
                                // }
                            ],
                        },
                    ],
                };
            }

            // else {
            //     slackData = { text: type + ' ```' + JSON.stringify(error) + '```' };
            // }
        } catch (e) {
            return { text: type + ' ```' + e + '```' };
        }
        return slackData;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendError(title: string, error: any, file?: string): void {
        this.sendToSlack(this.generateSlackObj(title, 'ERROR', error, file));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendWarning(title: string, error: any, file?: string): void {
        this.sendToSlack(this.generateSlackObj(title, 'WARNING', error, file));
    }

    public sendInfo(title: string): void {
        const slackData = {
            attachments: [
                {
                    fallback: title,
                    color: '#0072bc',
                    pretext: title,
                    // author_name: title,
                    text: 'Optional text that appears within the attachment',
                },
            ],
        };
        this.sendToSlack2(slackData);
    }

    public getUserData(): Observable<User> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/userinfo/');
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getWizardTodoList(contractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getWizardApi() + `/contractors/${contractorId}/wizards`);
        // return this.httpClient.get(this.helper.getWizardApi() + `/contractors/${contractorId}/wizards`).pipe(
        //     map(
        //         (todoList: WizardListElement[]) => {
        //             //filter by active Modules
        //             return todoList.filter(
        //                 (todoElement) =>
        //                     todoElement.modules?.every((module) => this.activeModules.isAM(module as AppModulesType)) ||
        //                     !todoElement?.modules?.length,
        //             );
        //         },
        //         //filter by active views
        //         map((filteredByActiveModules: WizardListElement[]) => {
        //             return filteredByActiveModules.filter(
        //                 (todoElement2) =>
        //                     todoElement2.views?.every((view) => this.menuService.isAV(view)) ||
        //                     !todoElement2?.views?.length,
        //             );
        //         }),
        //     ),
        // );
    }

    public changeTodoListStatus(completed: boolean, contractorId: number, wizardId: number): Observable<WizardListElement[]> {
        if (completed) {
            return this.httpClient.put(this.helper.getWizardApi() + `/contractors/${contractorId}/wizards/${wizardId}/complete`);
        } else {
            return this.httpClient.delete(this.helper.getWizardApi() + `/contractors/${contractorId}/wizards/${wizardId}/delete`);
        }
    }
    public getMetabaseMaskedId(contractorId: number): Observable<string> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/metabaseMaskedId`, {
            responseType: 'text',
        });
    }
    // public getSubcontractorData(): Observable<Subcontractor> {
    //     return this.httpClient.get(this.helper.getApiUrl(2) + '/userinfo/');
    // }
}
