<div style="position: relative">
    <form *ngIf="isPermissionsLoaded" [formGroup]="customerDetailsForm" novalidate>
        <div class="m-list-search">
            <div class="m-list-search__results">
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i
                            ngbTooltip="Ime in priimek"
                            class="la la-user m--font-inverse-light"
                            i18n-ngbTooltip="@@fa106098f4b22df6f1678e36cb9c9c8683f31072"
                        ></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="name"
                            type="text"
                            placeholder="Ime"
                            class="form-control m-input capitalize"
                            i18n-placeholder="@@4f8e8a32a405e7b6b267c8a3aebc6086c0af6f57"
                        />
                    </span>
                </div>
                <div *ngIf="formData['name'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['name'].errors.required" i18n="@@d893942a3663a86744e2d9730fa1f2e7f7653fc6">
                        Ime je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon"></span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="surname"
                            type="text"
                            placeholder="Priimek"
                            class="form-control m-input capitalize"
                            i18n-placeholder="@@359f4a90716983d72c0eea56dc3c4eb3b1adf76e"
                        />
                    </span>
                </div>
                <div *ngIf="formData['surname'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['surname'].errors.required" i18n="@@4509d3738b445020a6dcc819215e33ebb0035967">
                        Priimek je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i ngbTooltip="Spol" class="la la-transgender m--font-inverse-light"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <div class="m-radio-inline">
                            <label class="m-radio m-radio--state-brand">
                                <input type="radio" formControlName="gender" value="FEMALE" />
                                Ženski
                                <span></span>
                            </label>
                            <label class="m-radio m-radio--state-brand">
                                <input type="radio" formControlName="gender" value="MALE" />
                                Moški
                                <span></span>
                            </label>
                        </div>
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i
                            ngbTooltip="Dan rojstva"
                            class="la la-birthday-cake m--font-inverse-light"
                            i18n-ngbTooltip="@@984f52037867e84264486711863b4d10fc5ae424"
                        ></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            [textMask]="{ mask: dateOfBirthMask, keepCharPositions: true }"
                            formControlName="birthDate"
                            type="text"
                            placeholder="dd.mm.llll"
                            class="form-control m-input"
                            i18n-placeholder="@@09afb82f5fc85cbde71de947edc2e3083d49ba3f"
                        />
                    </span>
                </div>
                <div *ngIf="formData['birthDate'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['birthDate'].errors.required" i18n="@@eb6afc14901fe669d3017bbc405e0b1981a13754">
                        Datum rojstva je obvezno polje.
                    </small>
                    <small [hidden]="!formData['birthDate'].errors.pattern">Napačna oblika datuma DD.MM.YYYY</small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i
                            ngbTooltip="Naslov bivanja"
                            class="la la-map-marker m--font-inverse-light"
                            i18n-ngbTooltip="@@e56a8a6acd7cfb9ba01e329bb5f3ced911eddc65"
                        ></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="address"
                            type="text"
                            placeholder="Naslov"
                            class="form-control m-input"
                            i18n-placeholder="@@b3765ea82dad433b6f972ab8653c72aa46f86e91"
                        />
                    </span>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon"></span>
                    <span class="m-list-search__result-item-text">
                        <div class="d-flex flex-wrap w-100">
                            <div class="mf-column">
                                <ng-select
                                    [items]="zipCodes"
                                    [addTag]="true"
                                    [clearable]="false"
                                    (change)="fillCity($event)"
                                    bindLabel="zipCode"
                                    bindValue="zipCode"
                                    addTagText="Dodaj poštno št."
                                    placeholder="Poštna številka"
                                    formControlName="post"
                                    i18n-placeholder="@@post_number"
                                >
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>
                                            {{ item.zipCode }}
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="mf-column">
                                <ng-select
                                    [items]="zipCodes"
                                    [addTag]="true"
                                    [clearable]="false"
                                    (change)="fillPost($event)"
                                    bindLabel="city"
                                    bindValue="city"
                                    addTagText="Dodaj pošto"
                                    placeholder="Pošta"
                                    formControlName="city"
                                    i18n-placeholder="@@post_city"
                                >
                                    <ng-template style="overflow-y: scroll" ng-option-tmp let-item="item">
                                        <div>
                                            {{ item.city }}
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span
                            ngbTooltip="Elektronska pošta"
                            class="la la-envelope m--font-inverse-light"
                            i18n-ngbTooltip="@@b1fb7bf9ae1c705e5eb894408a74181f7c52a649"
                        ></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="email"
                            type="text"
                            placeholder="Email"
                            class="form-control m-input"
                            i18n-placeholder="@@244aae9346da82b0922506c2d2581373a15641cc"
                        />
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span
                            [tphCallNumber]="formData['phone'].value"
                            [isCompact]="true"
                            ngbTooltip="Kontaktni telefon"
                            class="la la-phone m--font-inverse-light"
                            i18n-ngbTooltip="@@457040b8a75dd15c92036f0aa4def4a50a453311"
                        ></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="phone"
                            type="text"
                            placeholder="Telefon"
                            class="form-control m-input"
                            i18n-placeholder="@@cbbc5fc52b2dbad2f6313f78cd491475b7df01a9"
                        />
                    </span>
                </div>
                <div *ngIf="formData['phone'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['phone'].errors.required" i18n="@@717b944dd0c817285308b7a5e0e8d613873a9e7c">
                        Telefon je obvezno polje.
                    </small>
                    <small [hidden]="!formData['phone'].errors.minlength">Dolžina telefonske številke je prekratka. Vnesite vsaj 9 števil.</small>
                </div>

                <customer-administrator [parent]="customerDetailsForm" [setIsSubmited]="isSubmited"></customer-administrator>
                <div formGroupName="customerId">
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span
                                ngbTooltip="Številka zavarovanja (KZZ)"
                                class="mf-text-icon"
                                i18n-ngbTooltip="@@e17287b46b3d578a279b7cca9ffced3321b8eb34"
                                i18n="@@aaf4b1dafc76f7a78f1175a306e71868b508a22e"
                            >
                                KZZ
                            </span>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="kzz"
                                type="text"
                                placeholder="KZZ"
                                class="form-control m-input"
                                i18n-placeholder="@@aaf4b1dafc76f7a78f1175a306e71868b508a22e"
                            />
                        </span>
                    </div>
                    <div *ngIf="formData['customerId'].controls['kzz'].errors && isSubmited" class="m--font-danger pl-5">
                        <small [hidden]="!formData['customerId'].controls['kzz'].errors.minlength" i18n="@@30806967807ff69c19dd3f2ee313418f2535a419">
                            Dolžina KZZ številke je prekratka. Vnesite 9 števil.
                        </small>
                        <small [hidden]="!formData['customerId'].controls['kzz'].errors.maxlength" i18n="@@70f195204dcbb98674d208ceae7a731a236b0b1f">
                            Dolžina KZZ številke je predolga. Vnesite 9 števil.
                        </small>
                        <small [hidden]="!formData['customerId'].controls['kzz'].errors.pattern" i18n="@@a8ba91d37f2a1c0c65a3805dd356518a9f38b929">
                            Vsi znaki niso številke.
                        </small>
                    </div>
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span
                                ngbTooltip="EMŠO številka"
                                class="mf-text-icon"
                                i18n-ngbTooltip="@@651edfe232b5eb83c29409116b9d544a6b2c025d"
                                i18n="@@5a05b27b2742261532c935ab5d7ecd71ed78adca"
                            >
                                EMŠO
                            </span>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="emso"
                                type="text"
                                placeholder="EMŠO"
                                class="form-control m-input"
                                i18n-placeholder="@@5a05b27b2742261532c935ab5d7ecd71ed78adca"
                            />
                        </span>
                    </div>
                    <div *ngIf="formData['customerId'].controls['emso'].errors && isSubmited" class="m--font-danger pl-5">
                        <small [hidden]="!formData['customerId'].controls['emso'].errors.minlength" i18n="@@dfdae602871fdc8228073f64046daa04a92258f9">
                            Dolžina EMŠO številke je prekratka. Vnesite 13 števil.
                        </small>
                        <small [hidden]="!formData['customerId'].controls['emso'].errors.maxlength" i18n="@@582f0a01744cc4521aec6b2bfcd963ff30d1fec5">
                            Dolžina EMŠO številke je predolga. Vnesite 13 števil.
                        </small>
                        <small [hidden]="!formData['customerId'].controls['emso'].errors.pattern" i18n="@@a8ba91d37f2a1c0c65a3805dd356518a9f38b929">
                            Vsi znaki niso številke.
                        </small>
                    </div>
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span
                                ngbTooltip="Davčna številka"
                                class="mf-text-icon"
                                i18n-ngbTooltip="@@483f34d638189863543bd6e692fe8adc47beb4e6"
                                i18n="Davčna številka"
                            >
                                DŠ
                            </span>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="taxNo"
                                type="text"
                                placeholder="Davčna številka"
                                class="form-control m-input"
                                i18n-placeholder="@@483f34d638189863543bd6e692fe8adc47beb4e6"
                            />
                        </span>
                    </div>
                    <div *ngIf="formData['customerId'].controls['taxNo'].errors && isSubmited" class="m--font-danger pl-5">
                        <small
                            [hidden]="!formData['customerId'].controls['taxNo'].errors.minlength"
                            i18n="@@f64b0d4c1229e829ca71c239226e4a3fac228b5d"
                        >
                            Dolžina davčne številke je prekratka. Vnesite 8 števil.
                        </small>
                        <small
                            [hidden]="!formData['customerId'].controls['taxNo'].errors.maxlength"
                            i18n="@@b0a17d187110577e4b39e7b365d527902138639c"
                        >
                            Dolžina davčne številke je predolga. Vnesite 8 števil.
                        </small>
                        <small [hidden]="!formData['customerId'].controls['taxNo'].errors.pattern" i18n="@@a8ba91d37f2a1c0c65a3805dd356518a9f38b929">
                            Vsi znaki niso številke.
                        </small>
                    </div>

                    <ng-container *ngIf="isMdpsModuleActive">
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <span ngbTooltip="Naziv delovnega mesta" class="la la-info m--font-inverse-light"></span>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input formControlName="jobTitle" type="text" placeholder="Naziv delovnega mesta" class="form-control m-input" />
                            </span>
                        </div>
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <span ngbTooltip="Koda delovnega mesta" class="la la-info m--font-inverse-light"></span>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input formControlName="jobCode" type="text" placeholder="Koda delovnega mesta" class="form-control m-input" />
                            </span>
                        </div>
                        <div *ngIf="formData['customerId'].controls['jobCode'].errors && isSubmited" class="m--font-danger pl-5">
                            <small [hidden]="!formData['customerId'].controls['jobCode'].errors.minlength">
                                Dolžina kode je prekratka. Vnesite 4 števila.
                            </small>
                            <small [hidden]="!formData['customerId'].controls['jobCode'].errors.maxlength">
                                Dolžina kode je predolga. Vnesite 4 števila.
                            </small>
                            <small [hidden]="!formData['customerId'].controls['jobCode'].errors.pattern">Vsi znaki niso številke.</small>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="customer2.id" class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span
                            ngbTooltip="ID stranke"
                            class="la la-info m--font-inverse-light"
                            i18n-ngbTooltip="@@984aa96ea5d186f6a4250b937d758635348a5436"
                        ></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="idCustomer"
                            type="text"
                            placeholder="ID stranke"
                            class="form-control m-input"
                            i18n-placeholder="@@984aa96ea5d186f6a4250b937d758635348a5436"
                        />
                    </span>
                </div>
                <div *ngIf="customer2.id" class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span ngbTooltip="Ustvarjen v aplikaciji" class="la la-industry m--font-inverse-light" i18n-ngbTooltip></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <ng-container [ngSwitch]="customer2.createdFrom">
                            <span *ngSwitchCase="'ASISTENT'" class="m-badge m-badge--warning m-badge--wide">ASISTENT</span>
                            <span *ngSwitchCase="'HD'" class="m-badge m-badge--warning m-badge--wide">HD</span>
                            <span *ngSwitchCase="'G100'" class="m-badge m-badge--warning m-badge--wide">G100</span>
                            <span *ngSwitchCase="'PATIENT_APP'" class="m-badge m-badge--success m-badge--wide">SPLETNI OBRAZEC</span>
                            <span *ngSwitchCase="'EAMBULANTA'" class="m-badge m-badge--metal m-badge--wide">EAMBULANTA</span>
                            <span *ngSwitchCase="'PREVENTIVNI'" class="m-badge m-badge--metal m-badge--wide">PREVENTIVNI</span>
                            <span *ngSwitchDefault class="m-badge m-badge--metal m-badge--wide">{{ customer2.createdFrom }}</span>
                        </ng-container>
                    </span>
                </div>
            </div>
        </div>
    </form>
</div>

<mf-can-leave #canLeave [dontLeave]="customerDetailsForm && !customerDetailsForm.pristine"></mf-can-leave>
